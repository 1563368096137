$.lui("comp",".comp_timeline",function (selector) {
    function fn_last_item() {
        let items = selector.find('.elm_comp_item');

        items.each(function () {
            let item = $(this);
            let itemNext = item.next('.elm_comp_item');
            item.removeClass('is--last');

            if(itemNext.length) {
                if(itemNext.offset().top !== item.offset().top) {
                    item.addClass('is--last');
                }
            } else {
                item.addClass('is--last');
            }
        })
    }

    fn_last_item();

    win.on('resize', fn_last_item);
});
