$.lui("comp",".comp_text_qa",function (selector) {
    let showMoreButton = selector.find('[data-show-more]')

    showMoreButton.on('click', function(e){
        e.preventDefault();
        showMoreButton.parent().parent().addClass('state--active');
        showMoreButton.parent().hide();

        ScrollReveal().sync();
    })
});
